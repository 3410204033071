import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Variant } from '../pages/product/product-page/product.types';
import { StateService } from './state.service';
import { Store } from './store-serializer';
import {
  OGBaseMetaData,
  OGImageMetaData,
  OGMetaData,
  OGProductMetaData,
} from '../types/og-meta-data';
import { environment } from '../../environments/environment';
import { TranslocoService } from '@jsverse/transloco';
import { ProductListPage } from '../pages/product/product-list/product-list.types';
import { CmsPage } from '../pages/static/static.types';
import { LocalizedSlugs } from './spree-client/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private readonly titleService = inject(Title);
  private readonly metaService = inject(Meta);
  private readonly state = inject(StateService);
  private readonly document = inject(DOCUMENT);
  private readonly t = inject(TranslocoService);
  private readonly ogTagsChanged = new ReplaySubject<void>(1);
  private store!: Store;

  readonly ogTagsChanged$ = this.ogTagsChanged.asObservable();

  constructor() {
    this.state.store$.subscribe((store) => (this.store = store));
  }

  setTags(tags: Partial<OGMetaData>): void {
    this.titleService.setTitle(`${tags['og:title']} - ${this.store.seoTitle}`);
    this.updateOgTags({
      ...this.getHomepageOgTags(),
      ...tags,
    });
  }

  setForCheckout(): void {
    this.titleService.setTitle(
      `${this.t.translate('Checkout')} - ${this.store.seoTitle}`,
    );
    this.updateMetaDescription('');
    this.removeOgTags();
    this.ogTagsChanged.next();
  }

  setForCmsPage(page: CmsPage): void {
    this.titleService.setTitle(page.metaTitle);
    this.updateMetaDescription(page.metaDescription);
    this.updateOgTags(this.createCmsPageOgTags(page));
  }

  setForCart(): void {
    this.titleService.setTitle(`${this.t.translate('Cart')} - ${this.store.seoTitle}`);
    this.updateMetaDescription('');
    this.removeOgTags();
    this.ogTagsChanged.next();
  }

  setForProductList({ meta }: ProductListPage): void {
    this.updateHreflangs(meta.category.localizedSlugs);
    this.titleService.setTitle(`${meta.category.metaTitle} - ${this.store.seoTitle}`);
    this.updateMetaDescription(meta.category.metaDescription);
    this.updateOgTags(this.createProductListTags(meta));
  }

  setForHomepage(): void {
    this.updateHreflangs();
    this.titleService.setTitle(this.store.seoTitle);
    this.updateMetaDescription(this.store.metaDescription);
    this.updateOgTags(this.getHomepageOgTags());
  }

  setForProduct(variant: Variant | undefined): void {
    if (!variant) {
      return;
    }

    this.updateHreflangs(variant.localizedSlugs);

    this.titleService.setTitle(`${variant.name} - ${this.store.name}`);
    this.updateMetaDescription(variant.description);
    this.updateOgTags(this.createVariantOgTags(variant));
  }

  private updateMetaDescription(content: string | null | undefined): void {
    if (content == null) {
      this.metaService.removeTag('name=description');

      return;
    }

    this.metaService.updateTag({
      name: 'description',
      content: content.substring(0, 155),
    });
  }

  private updateOgTags(ogData: OGMetaData | OGProductMetaData): void {
    this.removeOgTags();
    Object.entries(ogData).forEach(([property, content]) => {
      this.metaService.addTag({ property, content });
    });
    this.ogTagsChanged.next();
  }

  private removeOgTags(): void {
    const ogTags =
      this.document.head.querySelectorAll<HTMLMetaElement>('[property^="og:"]');
    ogTags.forEach((tag) => this.metaService.removeTagElement(tag));
  }

  private createVariantOgTags(variant: Variant): OGProductMetaData {
    return {
      'og:title': variant.name,
      'og:description': variant.description,
      'og:image': variant.images[0].url,
      'og:image:url': variant.images[0].url,
      'og:image:alt': variant.images[0].alt,
      'og:type': 'og:product',
      'og:site_name': this.store.name,
      'og:url': this.document.location.href,
      'og:product:price:amount': variant.price.current?.toString(10),
      'og:product:price:currency': this.state.getActiveCurrency(),
    };
  }

  private createCmsPageOgTags(page: CmsPage): OGBaseMetaData {
    return {
      'og:description': page.metaDescription,
      'og:site_name': this.store.name,
      'og:title': page.metaTitle,
      'og:type': 'website',
      'og:url': this.document.location.href,
    };
  }

  private createProductListTags(
    meta: ProductListPage['meta'],
  ): OGBaseMetaData & OGImageMetaData {
    const imageTags = this.getDefaultImageTags();

    if (meta.category.image) {
      imageTags['og:image'] = meta.category.image.url;
      imageTags['og:image:url'] = meta.category.image.url;
      imageTags['og:image:alt'] = meta.category.image.alt ?? '';
    }

    return {
      'og:site_name': this.store.name,
      'og:type': 'website',
      'og:title': `${meta.category.metaTitle} - ${this.store.name}`,
      'og:description': meta.category.metaDescription,
      'og:url': this.document.location.href,
    };
  }

  private getHomepageOgTags(): OGBaseMetaData & OGImageMetaData {
    return {
      'og:site_name': this.store.name,
      'og:type': 'website',
      'og:title': this.store.seoTitle,
      'og:description': this.store.metaDescription,
      'og:url': this.document.location.href,
      ...this.getDefaultImageTags(),
    };
  }

  private getDefaultImageTags(): OGImageMetaData {
    return this.store.logoUrl
      ? {
          'og:image': `${this.state.apiUrl}${this.store.logoUrl}`,
          'og:image:url': `${this.state.apiUrl}${this.store.logoUrl}`,
          'og:image:alt': 'YES logo',
        }
      : {};
  }

  private updateHreflangs(slugs?: LocalizedSlugs): void {
    const defaultLang = this.store.defaultLocale;
    const availableLangs = this.store.supportedLocales;
    const typeValue = this.document.location.pathname.split('/');
    let type = '';

    const links = this.document.querySelectorAll(`link[rel*="alternate"]`);
    links.forEach((link) => link.parentNode?.removeChild(link));

    if (typeValue && typeValue.length > 1) {
      type = typeValue[2];
    }

    availableLangs.forEach((tag) => {
      let hrefLink = '';

      if (slugs && slugs[tag]) {
        hrefLink = `/${type}/${slugs[tag]}`;
      }

      const link = this.document.createElement('link') as HTMLLinkElement;
      link.rel = 'alternate';
      link.hreflang = tag;
      link.href = `${environment.host}${'/' + tag}${hrefLink}`;
      this.document.head.appendChild(link);

      if (tag === defaultLang) {
        const xDefault = this.document.createElement('link') as HTMLLinkElement;
        xDefault.rel = 'alternate';
        xDefault.hreflang = 'x-default';
        xDefault.href = `${environment.host}${'/' + tag}${hrefLink}`;
        this.document.head.appendChild(xDefault);
      }
    });
  }
}
